<template>
  <div class="dashboard-widget-template" v-loading="loadingWidgetResult" >
    <div :style="`
      padding: ${data.padding ? data.padding : 0}px;
      font-size: ${data.fontSize ? data.fontSize : 12}px;
      text-align: ${data.align ? data.align : 'center'};
      color: ${data.color ? data.color : 'black'};
    `">
      {{widgetResult}}
    </div>
  </div>
</template>

<script>
import ReportResult from "@/components/report/builder/ReportResult.vue";
import {WidgetViewMixin} from "@/components/home/dashboard/widgets/WidgetViewMixin";

export default {
  name: 'VQBCellWidgetTemplate',
  mixins: [WidgetViewMixin],
  components: {ReportResult},
  props: {},
  mounted() {
    this.loadWidgetResult()
  },
  data(){
    return {}
  },
  methods: {}
}
</script>